<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement @click="$emit('details', element)"
                    :class="element.getId() % 2 == 0 ? 'even': 'odd'" v-for="element in elements.getItems()"
                    v-bind:key="element.getId()">
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Call @click.stop :telephone="element.getTelephone()" />
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
              <div v-if="element.getLogo() && element.getLogo().url" style="display: inline-block; margin-right: 5px;">
                  <img :src="element.getLogo().url" alt="" style="height: 40px; " />
              </div>
            <div class="d-inline-block align-middle ml-2">
                {{ element.getName() }}
                <div v-if="element.sellsImportProducts()">
                  <span class="badge badge-info" >{{$t('farmers.import')}}</span>
                </div>
                <div>
                <img :src="'https://www.worldatlas.com/r/w425/img/flag/' + element.getCountry().toLowerCase() +'-flag.jpg'" v-if="element.getCountry()" style="height: 15px;" class="inline-block mr-2"  /> {{element.getCounty()}}
                </div>
            </div>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            {{ formatNumber(element.getTotalProducts()) }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ element.getProductCategoriesLabels() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <DayOfWeek :days="element.getSendToCollectionWeekDays()" :time="element.getSendToCollectionTime()"/>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Date :date="element.getUpdatedAt()"/>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Status :status="element.isActive() ? 'farmers.active' : 'farmers.inactive'" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Button v-if="canDeleteFarm" class="border-0 bg-white" @click.stop="onDelete(element)" name="farmers.buttons.delete">
              <span class="text-danger">X</span>
            </Button>
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('farmers.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Call from "@/components/elements/Call";
import Date from "@/components/elements/Date";
import Status from "@/components/elements/Status";
import Button from "@/components/elements/Button";
import EmittedEvent from "@/entities/EmittedEvent";
import {AuthorizedActionsMixin} from "@/mixins/GeneralMixin";
import DayOfWeek from "@/components/elements/DayOfWeek";

export default {
  name: "FarmerTable",
  mixins: [ScreenSizeMixin, NumbersMixin, AuthorizedActionsMixin],
  emits: ['confirm', 'details'],
  components: {DayOfWeek, Button, Status, Date, Call, TableElement, TableElementItem, Table},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
          '',
          'farmers.list.name',
          'farmers.list.products_count',
          'farmers.list.categories',
          'farmers.list.delivery_days',
          'farmers.list.last_update',
          'farmers.list.status',
          ''
      ],
      responsiveHeaders: [
        '',
        'farmers.list.name',
        'farmers.list.products_count',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    },
  },
  methods: {
    onDelete(element) {
      this.$emit('confirm', new EmittedEvent('farmers.list.confirmation.delete', element));
    }
  },
}
</script>

<style scoped>

</style>
