<template>
  <div v-if="days.length > 0">
    <div v-for="day in days" :key="day"> {{ formatDay(day) }}</div>
    <div v-if="time"> {{ time }}</div>
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
import {DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "DayOfWeek",
  mixins: [DatesMixin],
  props: {
    days: Array,
    time: {
      type: String,
      default: "",
    }
  },
}
</script>

<style scoped>

</style>