<template>
  <div class="row">
    <div class="col-lg-6">
      <UploadFileInput :placeholder="$t('farmers.modal.products')"/>
    </div>
    <div v-show="show_status" class="col-lg-3">
      <div v-show="showSuccessMessage" class="upload alert alert-success ml-md-2 mr-md-auto text-center"><span>{{ $t('general.status.success') }}</span></div>
      <div v-show="!showSuccessMessage" class="upload alert alert-warning ml-md-2 mr-md-auto text-center"><span>{{ $t('general.status.warnings') }}</span></div>
    </div>
  </div>
</template>

<script>
import UploadFileInput from "@/components/elements/UploadFileInput";

export default {
  name: "UploadProductFileInput",
  components: {UploadFileInput},
  props: {
    show_status: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    showSuccessMessage: function() {
      return 0 === this.errors;
    },
    showWarningMessage: function() {
      return this.errors > 0;
    }
  }
}
</script>

<style scoped>
.alert.upload {
  padding: .5rem 0rem
}
</style>