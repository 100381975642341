<template>
  <Modal :name="title" v-show="!isLoading" @closemodal="$emit('closemodal')" :with-footer="true" :dismissible="true">
    <template v-slot:default>
      <Card :title="'farmers.modal.group_info'" :with-footer="false" :collapsable="true">
        <template v-slot:default>
            <div class="row mb-2" v-if="image !== null">
                <UploadFileDragDropMultiple
                    class="col-12"
                    :class="{'error': v$.image.$error}"
                    v-model="image"
                    name="logo"
                    :disabled="isLoading"
                    :multiple="false"
                    :label="''"
                    @filesChanged="onLogoChanged"
                    :accepted-files="'.jpg,.jpeg,.png,.gif'"
                    placeholder="farmers.modal.logo.upload_label"
                    files-list-label="farmers.modal.logo.listing"
                    empty-message="farmers.modal.logo.empty">
                </UploadFileDragDropMultiple>
                <div class="input-errors" v-for="(error, index) of v$.image.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>{{ $t('farmers.modal.placeholder.alias') }}</label>
                  <TextInput :reference="farmer" v-bind:disabled="farmer.isLoaded()" @input="nameUpdate" :value="farmer.getName()" v-bind:class="v$.farmer.farmer.name.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.name.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                  <div class="form-group">
                      <label>{{ $t('farmers.modal.placeholder.brand_name') }}</label>
                      <TextInput :reference="farmer" @input="brandNameUpdate" :value="farmer.getBrandName()" :placeholder="$t('farmers.modal.placeholder.brand_name')" v-bind:class="v$.farmer.farmer.brand_name.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.brand_name.$errors" :key="index">
                          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                  </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('farmers.modal.placeholder.email') }}</label>
                        <TextInput :reference="farmer" @input="emailUpdate" :value="farmer.getEmail()"  v-bind:class="v$.farmer.farmer.email.$error && 'error'"/>
                        <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.email.$errors" :key="index">
                            <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('farmers.modal.placeholder.contact') }}</label>
                        <TextInput :reference="farmer" @input="telephoneUpdate" :value="farmer.getTelephone()"  v-bind:class="v$.farmer.farmer.telephone.$error && 'error'"/>
                        <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.telephone.$errors" :key="index">
                            <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                        </div>
                    </div>
                </div>
            </div>


          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t('farmers.modal.placeholder.country') }}</label>
                <SelectInput :class="{'error':v$.farmer.farmer.country.$error}" :reference="farmer" :options="countries" @input="countryUpdate" :selected="farmer.getCountry()"/>
                <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.country.$errors" :key="index">
                  <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t('farmers.modal.placeholder.county') }}</label>
                <TextInput :reference="farmer" @input="countyUpdate" :value="farmer.getCounty()"  v-bind:class="v$.farmer.farmer.county.$error && 'error'"/>
                <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.county.$errors" :key="index">
                  <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                </div>
              </div>
            </div>
          </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('farmers.modal.placeholder.address') }}</label>
                        <TextInput :reference="farmer" @input="addressUpdate" :value="farmer.getAddress().getFullAddress()" v-bind:class="v$.farmer.address.address.raw.$error && 'error'"/>
                        <div class="input-errors" v-for="(error, index) of v$.farmer.address.address.raw.$errors" :key="index">
                            <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('farmers.modal.placeholder.description') }}</label>
                        <TextAreaInput :reference="farmer" @input="descriptionUpdate" :value="farmer.getDescription()" v-bind:class="v$.farmer.farmer.description.$error && 'error'"/>
                        <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.description.$errors" :key="index">
                            <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <Toggle @update="isActiveUpdate" :checked="farmer.isActive()" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <Toggle @update="showCommissionDetailsUpdate" :checked_label="'farmers.modal.show_commission_details'" :unchecked_label="'farmers.modal.show_commission_details'" :checked="farmer.showCommissionDetails()" />
                    </div>
                </div>
            </div>



        </template>
      </Card>
        <Card :title="'farmers.modal.group_company'" :with-footer="false" :collapsable="true">
            <template v-slot:default>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.company_name') }}</label>
                            <TextInput :reference="farmer" @input="companyNameUpdate" :value="farmer.getCompany().getCompanyName()" v-bind:class="v$.farmer.company.company_name.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.company.company_name.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.company_address') }}</label>
                            <TextInput :reference="farmer" @input="companyAddressUpdate" :value="farmer.getCompany().getCompanyAddress()" v-bind:class="v$.farmer.company.company_address.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.company.company_address.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.vat') }}</label>
                            <TextInput :reference="farmer" @input="vatUpdate" :value="farmer.getCompany().getVat()" v-bind:class="v$.farmer.company.vat.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.company.vat.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.cui') }}</label>
                            <TextInput :reference="farmer" @input="cuiUpdate" :value="farmer.getCompany().getCui()" v-bind:class="v$.farmer.company.cui.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.company.cui.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('farmers.modal.placeholder.legal_type') }}</label>
                    <SelectInput :class="{'error':v$.farmer.company.legal_type.$error}" :reference="farmer" :options="legalTypes" @input="legalTypeUpdate" :selected="farmer.company.getLegalType()"/>
                    <div class="input-errors" v-for="(error, index) of v$.farmer.company.legal_type.$errors" :key="index">
                      <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
        </Card>
        <Card :title="'farmers.modal.group_products'" :with-footer="false" :collapsable="true">
            <template v-slot:default>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.products_description') }}</label>
                            <TextAreaInput :reference="farmer" @input="productsDescriptionUpdate" :value="farmer.getProductsDescription()" v-bind:class="v$.farmer.farmer.products_description.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.products_description.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.product_categories') }}</label>
                            <Select2 :model-value="farmer.product_categories.map(cat => cat.id)" @select="setCategories($event)" :options="categoriesForSelect2" :settings="categoriesSelect2settings" v-bind:class="v$.farmer.product_categories.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.product_categories.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <Toggle @update="sellsImportProductsUpdate" :checked_label="'farmers.modal.sells_import_products'" :unchecked_label="'farmers.modal.sells_import_products'" :checked="farmer.sellsImportProducts()" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <UploadProductFileInput @change="setUploadedFile" :show_status="meta.products > 0" :loading="isLoading" :errors="meta.errors" />
                    </div>
                </div>

            </template>
        </Card>
      <Card :title="'farmers.modal.group_certifications'" :with-footer="false" :collapsable="true">
        <template v-slot:default>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <Toggle @update="isBioUpdate" :checked_label="'farmers.modal.placeholder.bio'" :unchecked_label="'farmers.modal.placeholder.bio'" :checked="farmer.getIsBio()" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <Toggle @update="isMountainUpdate" :checked_label="'farmers.modal.placeholder.mountain'" :unchecked_label="'farmers.modal.placeholder.mountain'" :checked="farmer.getIsMountain()" />
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ $t('farmers.modal.placeholder.certifications') }}</label>
                <TextAreaInput :reference="farmer" @input="certificationsUpdate" :value="farmer.getCertifications()" v-bind:class="v$.farmer.farmer.certifications.$error && 'error'"/>
                <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.certifications.$errors" :key="index">
                  <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                </div>
              </div>
            </div>
          </div>

        </template>
      </Card>
        <Card :title="'farmers.modal.group_operations'" :with-footer="false" :collapsable="true">
            <template v-slot:default>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t('farmers.modal.placeholder.production_capacity') }}</label>
                    <TextAreaInput :reference="farmer" @input="productionCapacityUpdate" :value="farmer.getProductionCapacity()" v-bind:class="v$.farmer.farmer.production_capacity.$error && 'error'"/>
                    <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.production_capacity.$errors" :key="index">
                      <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                    </div>
                  </div>
                </div>
              </div>

                <div class="row">
                    <div class="col-lg-12">
                        <label>{{ $t('farmers.modal.placeholder.delivery_area') }}</label>
                        <SelectInput :class="{'error':v$.farmer.farmer.delivery_area.$error}" :reference="farmer" :options="deliveryOptions" @input="deliveryAreaUpdate" :selected="farmer.getDeliveryArea()"/>
                      <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.delivery_area.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                </div>
                <div class="row mt-4">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>{{ $t('farmers.modal.placeholder.min_order_local') }}</label>
                      <TextInput :reference="farmer" @input="minOrderLocalUpdate" :value="farmer.getMinOrderLocal()" v-bind:class="v$.farmer.farmer.min_order_local.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.min_order_local.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                    <div class="col-lg-4" v-if="farmer.getDeliveryArea() === 'eu' || farmer.getDeliveryArea() === 'national'">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.min_order_national') }}</label>
                            <TextInput :reference="farmer" @input="minOrderNationalUpdate" :value="farmer.getMinOrderNational()" v-bind:class="v$.farmer.farmer.min_order_national.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.min_order_national.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" v-if="farmer.getDeliveryArea() === 'eu'">
                        <div class="form-group">
                            <label>{{ $t('farmers.modal.placeholder.min_order_eu') }}</label>
                            <TextInput :reference="farmer" @input="minOrderEUUpdate" :value="farmer.getMinOrderEU()"  v-bind:class="v$.farmer.farmer.brand_name.$error && 'error'"/>
                            <div class="input-errors" v-for="(error, index) of v$.farmer.farmer.min_order_eu.$errors" :key="index">
                                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t('farmers.modal.placeholder.products_availability') }}</label>
                      <SelectMonths :reference="farmer" @input="productsAvailabilityUpdate" :selected="farmer.getProductsAvailability()"/>
                    </div>
                  </div>
                </div>

            </template>
        </Card>


      <NotificationAlert :notifications="notification"/>
    </template>
    <template v-slot:footer>
      <Button v-bind:disabled="canDisableButtons" @click="createFarmer" class="mr-auto" v-if="canCreateFarm && !farmer.isLoaded()" :name="$t('farmers.buttons.create')" type="confirm"></Button>
      <Button v-bind:disabled="canDisableButtons" @click="updateFarmer" class="mr-auto" v-else-if="canUpdateFarm && farmer.isLoaded()" :name="$t('farmers.buttons.update')" type="confirm"></Button>
    </template>
  </Modal>
</template>

<script>
import Card from "@/components/elements/Card";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import Toggle from "@/components/elements/Toggle";
import TextInput from "@/components/elements/TextInput";
import Select2 from 'vue3-select2-component';
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {NotificationsMixin, ValidationsMixin, AuthorizedActionsMixin, ErrorHandlerMixin} from "@/mixins/GeneralMixin";
import Farmer from "@/entities/Farmer";
import UploadProductFileInput from "@/components/farmer/backoffice/UploadProductFileInput";
import NotificationAlert from "@/components/elements/NotificationAlert";
import {email, required, numeric} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {ScrollMixin} from "@/mixins/ViewMixin";
import TextAreaInput from "@/components/elements/TextAreaInput.vue";
import UploadFileDragDropMultiple from "@/components/elements/UploadFileDragDropMultiple.vue";
import SelectInput from "@/components/elements/SelectInput.vue";
import ALL_COUNTRIES from "@/entities/Countries";
import SelectMonths from "@/components/elements/SelectMonths.vue";
const telephoneValidator = (value) => value && /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value);

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "FarmerModal",
  components: {
    SelectMonths,
      SelectInput,
      UploadFileDragDropMultiple,
      TextAreaInput, NotificationAlert, UploadProductFileInput, TextInput, Toggle, Button, Modal, Card, Select2},
  mixins: [LoadingModalMixin, NotificationsMixin, ValidationsMixin, AuthorizedActionsMixin, ErrorHandlerMixin, ScrollMixin],
  emits: ['closemodal'],
  props: {
    farmer: {
      type: Object,
      required: true,
    }
  },
  data: function () {
    let that = this;
    return {
        image: null,
      uploadedFile: null,
      categories: [],
      meta: {
        errors: 0,
        products: 0,
      },
      categoriesSelect2settings: {
        placeholder: this.$i18n.t('farmers.modal.placeholder.product_categories'),
        width: '100%',
        dropdownAutoWidth: true,
        multiple: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
          maximumSelected: () => that.$i18n.t("general.select.maxselection"),
        },
      },
    }
  },
  validations: {
      image: { },
    farmer: {
      address: {
        address: {
          raw: {
            required: required,
          }
        }
      },
      product_categories: {
      },
      farmer: {
        name: {
          required: required,
        },
        brand_name: {},
        description: {},
        email: {
          required: required,
          email: email
        },
        telephone: {
          required: required,
          telephone: telephoneValidator
        },
          country: {required},
          county: {required},
          products_description: {},
          production_capacity: {},
          products_availability: {},
          certifications: {},
          is_bio: {},
          is_mountain: {},
          min_order_local: {numeric},
          min_order_national: {numeric},
          min_order_eu: {numeric},
          delivery_area: {required}
      },
        company: {
          company_name: {required},
          company_address: {required},
          vat: {numeric, required},
          cui: {},
          legal_type: {required}
        }
    }
  },
    // watch: {
    //   "farmer.isReady": function (value) {
    //       console.log(value)
    //       if (this.farmer.getLogo())
    //           this.image = [this.farmer.getLogo().file]
    //   }
    // },
  computed: {
    title: function () {
      if (this.farmer === null || !this.farmer.isLoaded()) {
        return this.$t("farmers.modal.create_title");
      }

      return this.$t("farmers.modal.edit_title");
    },
    isFormReady: function () {
      return (this.farmer.isChanged() || this.farmer.company.isChanged() || this.farmer.address.isChanged()) && !this.v$.$invalid;
    },
    canDisableButtons: function () {
      return !this.farmer.isChanged() && !this.farmer.company.isChanged() && !this.farmer.address.isChanged();
    },
    categoriesForSelect2() {
      return this.categories.map(cat => {
        return {
          id: cat.id,
          text: cat.name
        }
      });
    },
      deliveryOptions() {
          return [
              {
                  val: 'local',
                  txt: this.$t('farmers.modal.delivery_area.local'),
              },
              {
                  val: 'national',
                  txt: this.$t('farmers.modal.delivery_area.national'),
              },
              {
                  val: 'eu',
                  txt: this.$t('farmers.modal.delivery_area.eu'),
              }
          ]
      },
    legalTypes() {
        return [
          {
            val: 'srl',
            txt: this.$t('farmers.modal.legal_type.srl'),
          },
          // {
          //   val: 'individual',
          //   txt: this.$t('farmers.modal.legal_type.individual'),
          // },
          {
            val: 'pfa',
            txt: this.$t('farmers.modal.legal_type.pfa'),
          },
          {
            val: 'atestat',
            txt: this.$t('farmers.modal.legal_type.atestat'),
          },
          {
            val: 'other',
            txt: this.$t('farmers.modal.legal_type.other'),
          },
        ]
    },
      countries() {
          return ALL_COUNTRIES.map(country => {
              return {
                  val: country.code,
                  txt: country.name
              }
          })
      },
  },
    beforeMount() {
    this.getProductCategories();
  },
    mounted() {
      console.log(this.farmer.getLogo());
      if (this.farmer && this.farmer.getLogo())
        this.image = [this.farmer.getLogo()]
        else this.image = [];
    },
    methods: {
    getProductCategories() {
      let that = this;

      this.queueJob();
      this.axios.get(this.$store.state.config.getProductCategoriesUri()).then(
          response => {
            let content = response.data;
            this.categories = content.data;
          }
      ).catch(
          error => {
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    setCategories(selectedObject)
    {
      if (!selectedObject.selected) {
        this.farmer.removeProductCategory(parseInt(selectedObject.id));
      } else {
        this.farmer.appendProductCategory(parseInt(selectedObject.id), selectedObject.text);
      }

      this.v$.farmer.product_categories.$touch();
    },
    reset: function () {
      this.meta.errors = 0;
      this.meta.products = 0;
    },
    onLoadingComplete: function ()
    {
      setTimeout(function () {
        document.getElementsByTagName("body")[0].classList.add('modal-open');
      }, 2000);
    },
    isActiveUpdate: function (value)
    {
      this.farmer.setIsActive(value);
    },
    showCommissionDetailsUpdate: function (value)
    {
      this.farmer.setShowCommissionDetails(value);
    },
    sellsImportProductsUpdate: function (value)
    {
      this.farmer.setSellsImportProducts(value);
    },
    nameUpdate: function (farmer, value)
    {
      farmer.setName(value);
      this.v$.farmer.farmer.name.$touch();
    },
    brandNameUpdate: function (farmer, value)
    {
        farmer.setBrandName(value);
        this.v$.farmer.farmer.brand_name.$touch();
    },
    descriptionUpdate: function (farmer, value)
    {
        farmer.setDescription(value);
        this.v$.farmer.farmer.description.$touch();
    },
    productionCapacityUpdate: function (farmer, value)
    {
      farmer.setProductionCapacity(value);
      this.v$.farmer.farmer.production_capacity.$touch();
    },
      certificationsUpdate: function (farmer, value)
      {
        farmer.setCertifications(value);
        this.v$.farmer.farmer.certifications.$touch();
      },
    companyNameUpdate(farmer, value)
    {
        farmer.getCompany().setCompanyName(value);
        this.v$.farmer.company.company_name.$touch();
    },
    companyAddressUpdate(farmer, value)
    {
        farmer.getCompany().setCompanyAddress(value);
        this.v$.farmer.company.company_address.$touch();
    },
    vatUpdate(farmer, value)
    {
        farmer.getCompany().setVat(value);
        this.v$.farmer.company.vat.$touch();
    },
    cuiUpdate(farmer, value)
    {
        farmer.getCompany().setCui(value);
        this.v$.farmer.company.cui.$touch();
    },
      legalTypeUpdate(farmer, value)
      {
        farmer.getCompany().setLegalType(value);
        this.v$.farmer.company.legal_type.$touch();
      },
      productsDescriptionUpdate: function (farmer, value)
      {
          farmer.setProductsDescription(value);
          this.v$.farmer.farmer.products_description.$touch();
      },
      isBioUpdate: function (value)
      {
          this.farmer.setIsBio(value);
      },
      isMountainUpdate: function (value)
      {
        this.farmer.setIsMountain(value);
      },
      minOrderLocalUpdate: function (farmer, value)
      {
        farmer.setMinOrderLocal(value);
        this.v$.farmer.farmer.min_order_local.$touch();
      },
        minOrderNationalUpdate: function (farmer, value)
        {
            farmer.setMinOrderNational(value);
            this.v$.farmer.farmer.min_order_national.$touch();
        },
        minOrderEUUpdate: function (farmer, value)
        {
            farmer.setMinOrderEU(value);
            this.v$.farmer.farmer.min_order_eu.$touch();
        },
        deliveryAreaUpdate: function (farmer, value)
        {
            farmer.setDeliveryArea(value);
            this.v$.farmer.farmer.delivery_area.$touch();
        },
      productsAvailabilityUpdate: function (farmer, value)
      {
        farmer.setProductsAvailability(value);
        this.v$.farmer.farmer.products_availability.$touch();
      },
    addressUpdate: function (farmer, value)
    {
      farmer.getAddress().setFullAddress(value);
      farmer.setChanged(true);
      this.v$.farmer.address.address.raw.$touch();
    },
    telephoneUpdate: function (farmer, value)
    {
      farmer.setTelephone(value);
      this.v$.farmer.farmer.telephone.$touch();
    },
    emailUpdate: function (farmer, value)
    {
      farmer.setEmail(value);
      this.v$.farmer.farmer.email.$touch();
    },
      countryUpdate: function (farmer, value)
      {
        farmer.setCountry(value);
        this.v$.farmer.farmer.country.$touch();
      },
      countyUpdate: function (farmer, value)
      {
        farmer.setCounty(value);
        this.v$.farmer.farmer.county.$touch();
      },
    updateFarmer: function ()
    {
      let that = this;

      let data = {
        'name': this.farmer.getName(),
        'brand_name': this.farmer.getBrandName(),
        'description': this.farmer.getDescription(),
        'telephone': this.farmer.getTelephone(),
        'email': this.farmer.getEmail(),
        'address': {
          "street": this.farmer.getAddress().getFullAddress()
        },
        "company_name": this.farmer.getCompany().getCompanyName(),
        "company_address": this.farmer.getCompany().getCompanyAddress(),
        "vat": this.farmer.getCompany().getVat(),
        "cui": this.farmer.getCompany().getCui(),
        "products_description": this.farmer.getProductsDescription(),
        "products_availability": this.farmer.getProductsAvailability(),
        "production_capacity": this.farmer.getProductionCapacity(),
        "certifications": this.farmer.getCertifications(),
        "is_bio": this.farmer.getIsBio(),
        "is_mountain": this.farmer.getIsMountain(),
        "country": this.farmer.getCountry(),
        "county": this.farmer.getCounty(),
        "delivery_area": this.farmer.getDeliveryArea(),
        "min_order_local": this.farmer.getMinOrderLocal(),
        "min_order_national": this.farmer.getMinOrderNational(),
        "min_order_eu": this.farmer.getMinOrderEU(),
        "product_categories": this.farmer.getProductCategories(),
        'active': this.farmer.isActive(),
        'show_commission_details': this.farmer.showCommissionDetails(),
        'sells_import_products': this.farmer.sellsImportProducts(),
        'legal_type': this.farmer.company.getLegalType(),
      }

      this.v$.farmer.$touch();

      if (!this.isFormReady) {
        return;
      }

      this.queueJob();
      this.resetNotifications();
      this.reset();
      this.axios.put(this.$store.state.config.getFarmerUri(this.farmer.getId()), data).then(
          async () => {

              let isUploadSuccess = true;
              if (this.image instanceof File) {
                  isUploadSuccess = await that.uploadLogo();
              }

              if (isUploadSuccess) {
                  await that.upload().then(() => {
                      that.addSuccessNotification("general.success");
                  });
                  //that.addSuccessNotification("general.success");
              } else {
                  that.addWarningNotification("general.upload_failed");
              }

            that.completeJob(true);

          }
      ).catch(
          error => {
            if ("response" in error) {
              console.warn(error);
              that.errorHandler(error);
            }
            that.scrollToNotificationBox();
            that.completeJob(true);
          }
      );
    },
    setUploadedFile(event)
    {
      this.uploadedFile = event.target.files[0];
      this.farmer.setChanged(true);
      this.reset();
      this.resetNotifications();
    },
    createFarmer: function ()
    {
      let that = this;

      let data = {
        'name': this.farmer.getName(),
        'brand_name': this.farmer.getBrandName(),
        'description': this.farmer.getDescription(),
        'telephone': this.farmer.getTelephone(),
        'email': this.farmer.getEmail(),
        'address': {
          "street": this.farmer.getAddress().getFullAddress(),
        },
        "company_name": this.farmer.getCompany().getCompanyName(),
        "company_address": this.farmer.getCompany().getCompanyAddress(),
        "vat": this.farmer.getCompany().getVat(),
        "cui": this.farmer.getCompany().getCui(),
        "products_description": this.farmer.getProductsDescription(),
        "products_availability": this.farmer.getProductsAvailability(),
        "production_capacity": this.farmer.getProductionCapacity(),
        "certifications": this.farmer.getCertifications(),
        "is_bio": this.farmer.getIsBio(),
        "is_mountain": this.farmer.getIsMountain(),
        "country": this.farmer.getCountry(),
        "county": this.farmer.getCounty(),
        "delivery_area": this.farmer.getDeliveryArea(),
        "min_order_local": this.farmer.getMinOrderLocal(),
        "min_order_national": this.farmer.getMinOrderNational(),
        "min_order_eu": this.farmer.getMinOrderEU(),
        "product_categories": this.farmer.getProductCategories(),
        'active': this.farmer.isActive(),
        'show_commission_details': this.farmer.showCommissionDetails(),
        'sells_import_products': this.farmer.sellsImportProducts(),
        'legal_type': this.farmer.company.getLegalType(),
      }

      this.v$.farmer.$touch();

      if (!this.isFormReady) {
        return;
      }

      this.queueJob();
      this.resetNotifications();
      this.reset();
      this.axios.post(this.$store.state.config.getFarmersUri(), data).then(
          async response => {
            let content = response.data;
            that.farmer.setId(content.data.id);
            that.farmer.setLoaded(true);

              let isUploadSuccess = true;
              if (this.image instanceof File) {
                  isUploadSuccess = await that.uploadLogo();
              }

              if (isUploadSuccess) {
                  await that.upload(new Farmer(content.data)).then(() => {
                      that.addSuccessNotification("general.success");
                  });
                  //that.addSuccessNotification("general.success");
              } else {
                  that.addWarningNotification("general.upload_failed");
              }

            that.completeJob(true);
            that.$emit('closemodal');
          }
      ).catch(
          error => {
            if ("response" in error) {
              console.warn(error);
              that.errorHandler(error);
            }
            that.scrollToNotificationBox();
            that.completeJob(true);
          }
      );
    },

      onLogoChanged() {
        this.farmer.setChanged(true);
          // if (this.image.length) {
          //     this.farmer.setLogo(this.image[0]);
          // }
          // else {
          //     this.farmer.setLogo(null);
          // }
      },

      uploadLogo: function ()
      {
          let formData = new FormData();
          formData.append('image', this.image);
          return this.axios.post(this.$store.state.config.getFarmerLogoUploadUri(this.farmer.getId()), formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }).then((response) => {
              this.farmer.setLogo(response.data.data.logo)
              this.image = [this.farmer.getLogo()]
              return true;
          }).catch((error) => {
              console.warn(error);
              return false;
          });
      },

    upload: function (farmer = null) {
      let that = this;
      let data = new FormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data',
          'Authorization': this.authorization,
        }
      }

      if (null == this.uploadedFile) {
        return Promise.resolve(true);
      }

      if (null === farmer) {
        farmer = this.farmer;
      }

      data.append('products', this.uploadedFile);
      that.uploadedFile = null;
      this.reset();
      return this.axios.post(this.$store.state.config.getProductsUploadUri(farmer.getId()), data, config).then(
          response => {
            let content = response.data;
            that.meta.products = content.data.length;
            if (content.messages) {
                return Promise.reject(content);
            }
            return Promise.resolve(content);
          }
      ).catch(
          error => {
            if ("response" in error) {
              console.warn(error);
              that.errorHandler(error);
            } else {
              that.meta.errors = error.messages.warnings.length
              error.messages.warnings.forEach(message => that.addWarningNotification(message));
              error.messages.errors.forEach(message => that.addErrorNotification(message));
            }

            return Promise.reject(error);
          }
      );
    }
  },
}
</script>

<style scoped>

</style>
