<template>
  <div class="col-lg-10">
    <input :disabled="disabled" type="file" class="form-control h-auto" v-bind:name="name">
    <span v-if="placeholder" class="form-text text-muted">{{ $t(placeholder) }}</span>
  </div>
</template>

<script>
export default {
  name: "UploadFileInput",
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
  pointer-events: none;
}
</style>