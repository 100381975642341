<template>
      <div class="flex w-full h-screen items-center justify-center text-center" >
      <div class="file-upload-container pl-3 pt-3 pr-3 border" :class="{'border-gray-300': withBorder, 'border-transparent': !withBorder}" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <h4 v-if="label">{{$t(label)}}</h4>

        <label v-if="!disabled" :for="'upload_'+name" class="block cursor-pointer position-relative mb-2">
          <input v-if="!disabled" type="file" :multiple="multiple" name="fields[assetsFieldHandle][]" :id="'upload_'+name"
                 class="w-100 h-100 opacity-0 overflow-hidden position-absolute" style="z-index: 5" @change="onChange" ref="file" :accept="acceptedFiles" />
          <Button :name="placeholder" type="upload"/>
        </label>
        <div class="text-center">
          <div class="mb-2 d-flex align-items-center justify-content-center" :class="emptyMessageClass" v-if="!filelist.length && !previousFiles.length">
            <i class="icon-exclamation mr-2" v-if="showEmptyAlert" style="font-size: 32px;"></i>
            <span class="text-left" style="font-size: 14px;" v-html="$t(emptyMessage)"></span>
          </div>
        </div>

        <div class="text-left" v-if="filelist.length || previousFiles.length" v-cloak>
          <h6 class="mt-2" v-if="filesListLabel">{{$t(filesListLabel)}}</h6>
          <div class="d-flex w-100 flex-nowrap  py-1" v-for="(file, index) in filelist" :key="index">
            <div class="flex-grow-1 text-break pr-2 font-size-sm py-1" >
              <a :href="file.url" v-if="file.file && file.url" target="_blank">{{filename(file.file)}}</a>
              <div v-else>{{ file.name }}</div>
            </div>
            <div>
              <a class="text-danger cursor-pointer" v-if="!disabled" @click="remove(index)"><i class="icon-folder-remove"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import Button from "@/components/elements/Button";
export default {
  components: {
    Button
  },
  props: {
    modelValue: {
      type: [String, Boolean, Array, File, Object],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    filesListLabel: {
      type: String,
      default: ""
    },
    emptyMessage: {
      type: String,
      default: ""
    },
    emptyMessageClass: {
      type: String,
      default: 'text-muted'
    },
    showEmptyAlert: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: true
    },
    acceptedFiles: {
      type: String,
      default: '.pdf,.jpg,.jpeg,.png,.gif'
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
  },
  data() {
    return {
      previousFiles: [],
      filelist: []
    }
  },
  watch: {
    modelValue(value) {
      if (!value) {
        this.filelist = [];
      }
      else {
        this.filelist = Array.isArray(value) ? value : [value];
      }
    },
    filelist: {
      deep: true,
      handler(value) {
        if (this.multiple) {
          this.value = value;
        }
        else {
          if (value && value.length) {
            this.value = value[0];
          }
          else {
            this.value = value;
          }
        }
      }
    }
  },
  mounted() {
    this.filelist = this.value;
  },
  methods: {
    removePrevious: function (index) {
      this.previousFiles.splice(index, 1);
    },

    onChange() {
      this.filelist = this.filelist.concat([...this.$refs.file.files]);
      if (!this.multiple) {
        this.filelist = [this.filelist[this.filelist.length-1]];
      }

      setTimeout(() => {
        this.$emit('filesChanged');
      })
    },
    remove(i) {
      this.filelist.splice(i, 1);
      setTimeout(() => {
        this.$emit('filesChanged');
      })
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('border-success')) {
        if (this.withBorder) {
          event.currentTarget.classList.remove('border-gray-300');
        }
        else {
          event.currentTarget.classList.remove('border-transparent');
        }
        event.currentTarget.classList.add('border-success');
      }
    },
    dragleave(event) {
      // Clean up
      if (this.withBorder) {
        event.currentTarget.classList.add('border-gray-300');
      }
      else {
        event.currentTarget.classList.add('border-transparent');
      }
      event.currentTarget.classList.remove('border-success');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      if (this.withBorder) {
        event.currentTarget.classList.add('border-gray-300');
      }
      else {
        event.currentTarget.classList.add('border-transparent');
      }
      event.currentTarget.classList.remove('border-success');
    },
    filename(filePath) {
      let parts = filePath.split('/');
      return parts[parts.length - 1];
    }
  }
};
</script>
