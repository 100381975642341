<template>
  <div>
    <a v-if="show" v-bind:href="'tel:'+telephone">
      <div class="d-flex align-items-center">
      <i class="icon-phone-wave mr-3" :class="{'icon-2x': big}"></i>
      <div>{{ telephone || $t('general.loading') }}</div>
      </div>
    </a>
    <a v-else v-bind:href="'tel:'+telephone"><i class="icon-phone-wave" :class="{'icon-2x': big}"></i></a>
  </div>
</template>

<script>
export default {
  name: "Call",
  props: {
    telephone: String,
    show: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
a {
  color: #333;
  text-decoration: none;
}
</style>
