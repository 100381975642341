<template>
  <textarea class="form-control" v-bind:placeholder="placeholder" v-model="inputVal"></textarea>
</template>

<script>
export default {
  name: "TextAreaInput",
  emits: ['input'],
  props: {
    reference: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: [String, Number],
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', this.reference, value);
      }
    }
  }
}
</script>

<style scoped>
.form-control:disabled {
  color: #333;
}
</style>