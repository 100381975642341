<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <FarmerDetailsModal @closemodal="selectedFarmer=null" v-if="selectedFarmer" :farmer="selectedFarmer" />
  <FarmerModal v-if="farmerToUpdate" @closemodal="onCloseFarmerModal" :farmer="farmerToUpdate" />
  <Card v-if="isFarmerListRoute" :title="$t('farmers.list.title')" :with-footer="canCreateFarm">
    <template v-slot:default>
      <FarmerTable @confirm="askConfirmation" @page="getFarmers" @details="showFarmerDetails" :elements="farmers" />
    </template>
    <template v-slot:footer>
      <Button v-if="canCreateFarm" @click="createOrUpdateFarmer" type="confirm" name="farmers.buttons.create" class="mb-0"/>
    </template>
  </Card>
  <router-view :key="rerender" @update="createOrUpdateFarmer" @refresh="getFarmers()"></router-view>
</template>

<script>
import Card from "@/components/elements/Card";
import {ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import LoadableCollection from "@/entities/LoadableCollection";
import Pagination from "@/entities/Pagination";
import Farmer from "@/entities/Farmer";
import Button from "@/components/elements/Button";
import FarmerTable from "@/components/farmer/backoffice/FarmerTable";
import FarmerDetailsModal from "@/components/farmer/backoffice/FarmerDetailsModal";
import {ScreenSizeMixin, AuthorizedActionsMixin} from "@/mixins/GeneralMixin";
import FarmerModal from "@/components/farmer/backoffice/FarmerModal";

export default {
  name: "FarmerList",
  mixins: [ConfirmationModalMixin, LoadingModalMixin, ScreenSizeMixin, AuthorizedActionsMixin],
  components: {FarmerModal, FarmerDetailsModal, FarmerTable, ConfirmationModal, Card, Button},
  data() {
    return {
      farmers: new LoadableCollection(),

      selectedFarmer: null,
      farmerToUpdate: null,
    }
  },
  mounted() {
    this.getFarmers();
  },
  computed: {
    isFarmerListRoute: function() {
      return this.$router.currentRoute.value.name === 'general.breadcrumb.farmers.list';
    },
    rerender: function() {
      return this.$store.state.rerender;
    },
  },
  methods: {
    onLoadingComplete: function ()
    {
      if (this.initialise) {
        this.getFarmers();
      }
    },
    confirmed: function() {
      switch (this.confirmationType) {
        case "farmers.list.confirmation.delete":
          this.deleteFarmer(this.confirmedEvent.getPayload());
          break;
      }
      this.closeConfirmation();
    },
    deleteFarmer: function (farmer = null){
      let that = this;
      this.queueJob();
      this.axios.delete(this.$store.state.config.getFarmerUri(farmer.getId())).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    createOrUpdateFarmer: function (farmer = null)
    {
      if (farmer != null && !this.canUpdateFarm) {
        return;
      }

      if (farmer instanceof Farmer) {
        this.farmerToUpdate = farmer;
        return;
      }

      this.farmerToUpdate = new Farmer();
    },
    showFarmerDetails: function (farmer) {
      if (!this.isScreenLgWidth) {
        this.selectedFarmer = farmer;
        return;
      }
      this.$router.push({name: 'general.breadcrumb.farmers.edit', params: {'id': farmer.getId()}});
    },
    onCloseFarmerModal: function () {
      this.farmerToUpdate = null;
      this.getFarmers();
    },
    getFarmers: function (pageNr = 1) {
      this.hydrateFarmers(this.farmers, null, pageNr);
      this.$store.commit('rerender');
    },
    hydrateFarmers(farmers, filter, pageNr = 1) {
      let that = this;
      let localFarmers = new LoadableCollection();
      let filterParams = {
        page: pageNr,
      };

      this.queueJob();
      this.axios.get(this.$store.state.config.getFarmersUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            localFarmers.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (farmer) {
              localFarmers.push(new Farmer(farmer));
            });

            localFarmers.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (localFarmers.isLoaded()) {
          farmers.empty();
          farmers.setPagination(localFarmers.getPagination());
          localFarmers.getItems().forEach(farmer => farmers.push(farmer));
          farmers.loaded();
        }
        that.completeJob();
      });
    },
  },
}
</script>

<style scoped>

</style>