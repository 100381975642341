<template>
  <div class="months-selector">
    <div class="mb-2">
      <a class="btn btn-sm btn-outline-primary mr-2" @click="selectAll" v-if="!allSelected" >{{$t('months.select_all')}}</a>
      <a class="btn btn-sm btn-outline-primary" @click="selectNone" v-if="anySelected" >{{$t('months.select_none')}}</a>
    </div>
    <label :for="'month_'+month" v-for="month in months" :key="month">
      <input type="checkbox" :id="'month_'+month" :value="month" v-model="value[month]" @change="onSelectionChange" :disabled="disabled"> {{$t('months.'+month)}}
    </label>
  </div>
</template>

<script>
export default {
  name: "SelectMonths",
  emits: ['input'],
  props: {
    reference: {
      type: Object,
      default: null,
    },
    selected: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    allSelected() {
      return Object.values(this.value).every(v => v);
    },
    anySelected() {
      return Object.values(this.value).some(v => v);
    }
  },
  data() {
    return {
      initialized: false,
      value:  {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false
      },
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },
  mounted() {
    if (this.selected) {
      this.value = this.selected;
    }
    setTimeout(() => {
      this.initialized = true;
    });
  },
  methods: {
    onSelectionChange() {
      if (this.initialized) {
        this.$emit('input', this.reference, this.value);
      }
    },
    selectNone() {
      if (!this.disabled) {
        this.value = {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
          10: false,
          11: false,
          12: false
        };
        this.$emit('input', this.reference, this.value);
      }
    },
    selectAll() {
      if (!this.disabled) {
        this.value = {
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          7: true,
          8: true,
          9: true,
          10: true,
          11: true,
          12: true
        };
        this.$emit('input', this.reference, this.value);
      }
    }
  }
}
</script>

<style scoped>
.form-control:disabled {
  color: #333;
}
.months-selector label {
  display: inline-block;
  margin-right: 1em;
  width: 10em;
}
.months-selector a.btn-outline-primary:hover {
  color: #ffffff;
}
</style>
