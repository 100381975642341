<template>
  <span>{{ date ? formatDate(date) : '-' }}</span>
</template>

<script>
import {DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "Date",
  mixins: [DatesMixin],
  props: {
    date: String,
  }
}
</script>

<style scoped>

</style>