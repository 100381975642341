<template>
  <Modal :dismissible="true" :with-footer="true">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('farmers.list.details.id')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{farmer.getId()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('farmers.list.details.name')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{farmer.getName()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('farmers.list.details.status')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          <Status :status="farmer.isActive() ? 'farmers.active' : 'farmers.inactive'"/>
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.list.details.products_count')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          {{ formatNumber(farmer.getTotalProducts()) }}
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.list.details.delivery_days')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          Mon, Tue
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.list.details.last_update')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Date :date="farmer.getUpdatedAt()" />
        </dd>
      </dl>
    </template>
    <template v-slot:footer>
      <Button @click="closeAndGo" class="mr-auto" :name="$t('farmers.buttons.edit')" type="eye"/>
      <Button v-if="canDeleteFarm" @click.stop="onDelete" name="farmers.buttons.delete" type="delete" />
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Status from "@/components/elements/Status";
import Button from "@/components/elements/Button";
import Date from "@/components/elements/Date";
import {AuthorizedActionsMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import EmittedEvent from "@/entities/EmittedEvent";

export default {
  name: "FarmerDetailsModal",
  components: {Date, Button, Status, Modal},
  mixins: [NumbersMixin, AuthorizedActionsMixin],
  emits: ['confirm'],
  props: {
    farmer: Object,
  },
  methods: {
    closeAndGo: function () {
      this.$router.push({name: 'general.breadcrumb.farmers.edit', params: {'id': this.farmer.getId()}});
      this.$emit('closemodal');
    },
    onDelete() {
      this.$emit('confirm', new EmittedEvent('farmers.list.confirmation.delete', this.farmer));
    }
  }
}
</script>

<style scoped>
</style>